.title {
  color: #c91681;
  padding-top: 20px;
  font-weight: 300;
  margin-bottom: 0.8em;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 1em;
  }
}

.standart {
  font-size: 60px;

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.small{
  font-size: 36px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
}