.overlay {
  position: fixed;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s, visibility 0s 0.15s;
}

.active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  transition: 0.2s;

  .menu {
    transform: translateX(0);
  }
}

.menu {
  background-color: #e8e8e8;
  width: 50vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  overflow: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  transition: all 0.2s;
  transform: translateX(-100%);

  @media (max-width: 500px) {
    width: 75%;
  }
}

.link {
  font-size: 18px;
  font-weight: 500;
  display: block;
  color: inherit;
  padding: 20px 15px;

  &.activeLink {
    color: #c91681;
  }
}

.phone {
  position: absolute;
  bottom: 20px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
}