.wrapper {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 50px;

  @media(max-width:768px) {
    flex-direction: column;
    row-gap: 10px;
    padding-bottom: 10px;
  }
}

.button {
  display: flex;
  justify-content: center;
  border: 1px solid #c91681;
  font-size: 18px;
  padding: 15px 40px;
  width: 256px;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
}

.buttonFirst {
  background-color: #c91681;
  color: #fff;
  
}

.buttonSecond {
  background-color: #fff;
  color: #c91681;
}