.wrapper {
  background-color: #e8e8e8;
  margin-top: 55px;
  padding: 75px 0;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 992px) {
    padding: 15px 0 75px;
  }

  @media (max-width: 768px) {
    padding: 15px 0 30px;
  }
}

.align {
  display: flex;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: 992px) {
    flex-direction: column;
    margin: 0;
  }
}

.nav {
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 992px) {
    width: 100%;
  }

  li {
    padding: 7px 0;

    >a {
      font-weight: 500;
      padding: 10px 15px;
      display: block;
      font-size: 16px;
      color: #000;
      white-space: nowrap;

      &:hover {
        background-color: #eee;
      }
    }
  }
}

.copyAlign {
  text-align: center;
  margin: 150px auto 0 auto;
  line-height: 100%;
  font-size: 20px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  >p {
    padding: 0 15px;
    position: relative;
    color: #737373;

    @media (max-width: 768px) {
      font-size: 12px;
    }

    &::after {
      content: "";
      height: 2px;
      width: 100%;
      top: -75px;
      left: 15px;
      position: absolute;
      background-color: #737373;
      box-sizing: border-box;

      @media (max-width: 992px) {
        width: calc(100% - 30px);
      }

      @media (max-width: 768px) {
        top: -25px
      }
    }
  }
}