.swiper-pagination-bullets.swiper-pagination-horizontal {
  font-size: 0;
  bottom: 0 !important;
}

.customBullet {
  width: calc(350px/5);
  height: 6px;
  display: inline-block;
  opacity: 0.1;
  background-color: #c91681;
  z-index: 100;
  cursor: pointer;
  transition: all 0.2s ease;
}

.customBullet:hover {
  opacity: 0.5;
}

.activeCustomBullet {
  opacity: 1;
  background-color: #c91681;
  transition: all 0.5s cubic-bezier(0.55, 0.06, 0.68, 0.19);
}