.button {
  display: grid;
  place-content: center;
  font-size: 0;
  line-height: 0;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  opacity: 0.75;
  background-color: white;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  -webkit-tap-highlight-color: transparent;
  z-index: 10;
  outline: 1px solid #222;
  box-shadow: 0px -3.5px 0px #222 inset;
  transition: 0.2s;


  &[data-dir="left"] {
    transform: scaleX(-1);

    &:active {
      transform: scaleX(-1) translateY(2px);
    }
  }

  &:hover {
    opacity: 1;
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px -2.5px 0px #222 inset;
  }
}

.arrow {
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
  margin-right: 3px;
  background-color: transparent;
  border-top: 4px solid #222;
  border-right: 4px solid #222;
  transform: rotate(45deg);
}