.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 1000;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s 0.3s;
  padding: 30px 10px 10px;
  display: flex;
  justify-content: center;
  pointer-events: none;

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  transition: 0.2s;
  pointer-events: all;

  .content {
    opacity: 1;
    transition: 0.3s;
    transition-delay: 0.2s;
    transform: translateY(0);
  }
}

.content {
  background-color: #fff;
  transform: translateY(-30%);
  transition: 0.3s;
  transition-delay: 0s;
  opacity: 0;
  position: relative;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  height: max-content;
  width: max-content;

  @media (max-width: 768px) {
    width: 100%;
  }
}