.wrapper {
  position: relative;
  padding: 80px 100px 100px;
  font-family: Arial, Helvetica, sans-serif;
  width: 1050px;

  @media (max-width: 1200px) {
    padding: 50px;
    width: 900px;
  }

  @media (max-width: 992px) {
    width: 750px;
  }

  @media (max-width: 768px) {
    width: auto;
    margin: 10px;
    padding: 80px 20px 30px;
  }
}

.title {
  font-family: 'RobotoMono';
  font-weight: 300;
  margin: 0 0 50px;
  font-size: 48px;
  line-height: 60px;

  @media (max-width: 992px) {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }
}

.categoryTitle {
  margin: 0 0 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;

  @media (max-width: 992px) {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
  }
}

.slider {
  border: none;
  cursor: pointer;
  display: block;
  height: 7px;
}

.sliderThumb {
  background-color: #c91681;
  cursor: pointer;
  display: block;
  position: absolute;
  width: 14px;
  height: 25px;
  bottom: -7px;
  transition: 0.1s;
  clip-path: polygon(50% 0, 50% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);

  &:hover,
  &:active,
  &:focus {
    outline: none;
    background: #dadada;
  }
}

.sliderTrack {
  height: 7px;
  position: absolute;
  left: 0px;
  bottom: 0;
  transition: 0.1s;

  &:nth-child(1) {
    background-color: #c91681;
  }

  &:nth-child(2) {
    background-color: #c4c4c4;
  }
}

.company {
  margin-bottom: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (max-width: 992px) {
    margin-bottom: 50px;
  }
  @media (max-width: 768px) {
    row-gap: 10px;
  }
}

.productNotice {
  position: absolute;
  top: 100%;
  font-size: 12px;
  line-height: 24px;
  color: #a3a3a3;
}

.placeWrapper {
  margin-bottom: 30px;
  position: relative;
}

.place {
  display: flex;
  flex-direction: column;
  width: 380px;
  row-gap: 15px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.placeInput {
  display: none;
}

.helpBlock {
  margin-top: 7px;
  color: #f00;
  margin-bottom: 0;
}

.date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin-bottom: 30px;
}

.datepicker {
  color: #a3a3a3;
  max-width: 130px;
  margin: 0;
}

.budget {
  max-width: 130px;
  color: #c91681;
  margin-bottom: 30px;
}

.moneyWrapper {
  margin-bottom: 30px;
}

.inputGroup {
  width: 100%;
  display: grid;
  margin-bottom: 30px;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    margin-bottom: 10px;
    row-gap: 10px;
  }
}

.closeBtn {
  position: absolute;
  top: 40px;
  right: 40px;

  @media (max-width: 768px) {
    top: 20px;
    right: 20px;
  }
}

.stepProgress {
  position: absolute;
  top: 1%;
  right: 60%;
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.stepProgress>span {
  position: absolute;
  display: block;
  width: 120px;
  left: 130%;
  top: 25%;
  height: 4px;
  background-color: #c4c4c4;
}

.stepProgress>span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background-color: #c91681;
}

.stepProgressOne>span::before {
  width: 40px;
}

.stepProgressTwo>span::before {
  width: 80px;
}

.stepProgressThree>span::before {
  width: 100%;
}

.step {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }

  &.active {
    display: block;
  }
}

.prevBtn {
  width: 100%;
  margin: 0 auto;
  padding: 20px 50px;
  font-size: 14px;
  border-radius: 0;
  line-height: 20px;
  text-align: center;
  border: 1px solid #c91681;
  background-color: #fff;
  color: #c91681;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.nextBtn {
  margin-bottom: 30px;
  margin: 0 auto;
  padding: 20px 50px;
  font-size: 14px;
  border-radius: 0;
  line-height: 20px;
  text-align: center;
  background-color: #c91681;
  color: #fff;
  display: none;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: block;
  }
}

.submitBtn {
  margin-top: 40px;
  width: 280px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }
}

.agreement {
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.error {
  color: #ff0000;
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: -15px;
}