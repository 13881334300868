.wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  left: 10px;
  top: 50%;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  overflow: hidden;

  &:checked~.switch {
    &::before {
      background-color: #c91681;
      transition: 0.1s;
    }

    &::after {
      transform: translateX(18px);
      transition: 0.1s;
    }
  }
}

.label {
  padding-left: 50px;
  font-weight: 400;
  user-select: none;
  cursor: pointer;
  width: max-content;
}

.switch {
  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    width: 40px;
    height: 20px;
    border: 1px solid transparent;
    border-radius: 20px;
    background-color: #c4c4c4;
    transition: 0.1s;
  }

  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 7px);
    left: 4px;
    width: 14px;
    height: 14px;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: #fff;
    transition: 0.1s;
  }
}