.activeHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  padding: 10px 0;
  transition: all 1s;
  border-bottom: 1px solid #eee;

  @media (max-width: 992px) {
    display: none;
  }
}

.hidden {
  transform: translateY(-100%);
}

.innerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeLinkWrapper {
  height: 100%;
  align-self: center;
}

.buttons {
  display: flex;
  gap: 40px;
  margin-right: -15px;
}

.homeLinkWrapper {
  height: max-content;
  line-height: 20px;
  padding: 12px 15px;
}

.homeLinkTitle {
  font-family: 'MontserratSemiBold';
  font-size: 20px;
  letter-spacing: 2.5px;
  color: #000;
}

.homeLinkText {
  color: #000;
  font-size: 12px;
  margin: 0;
}

.brief,
.call {
  font-size: 16px;
  text-decoration: underline;
}

.brief {
  color: #c91681;
}

.call {
  color: #1f67ff;
}