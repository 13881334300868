.sliderContainer {
  height: max-content;
  position: relative;
  padding: 50px 15px 0;
  margin: 30px auto 0;

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding-top: 0;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

.slider {
  width: 350px;
  height: 410px;
  padding-bottom: 6px !important;

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    height: 350px;
  }
}

.slide {
  position: relative;
}

.slideImg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navigationPrev,
.navigationNext {
  position: absolute;
  top: 52%;

  @media (max-width: 768px) {
    display: none !important;
  }
}

.navigationPrev {
  left: 5px;
}

.navigationNext {
  right: 5px;
}