.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #ff66c1;
  background-image: linear-gradient(to left, #ff66c1 70%, #e835a0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  border: 1px solid transparent;
  border-radius: 0;
  margin-bottom: -55px;
}

.circleSvg {
  position: absolute;
  top: 5px;
  left: 5px;
}

.textCircleWrapper {
  position: relative;
  width: 430px;
  height: 440px;
  margin: 30px 10% auto auto;
  background-image: url(../../../assets/image/disk.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: drop-shadow(-10px 10px 20px #ca1782);

  @media (max-width: 1400px) {
    margin-right: 30px;
  }

  @media (max-width: 992px) {
    width: 350px;
    height: 360px;
  }

  @media (max-width:768px) {
    width: 300px;
    height: 310px;
    margin-top: 110px;
  }

  @media (max-width: 541px) {
    width: 180px;
    height: 190px;
    margin-right: 5px;
  }
}

.textCircle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;

  tspan {
    font-weight: 700;
  }
}

.circleLink {
  font-weight: 700;
  text-decoration: underline;
}

.logoLink {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  top: 120px;
  left: 120px;

  @media (max-width: 992px) {
    top: 105px;
    left: 95px;
  }

  @media (max-width: 768px) {
    top: -80px;
    left: -60%;
  }

  @media (max-width: 541px) {
    left: -30%;
  }
}

.linkTitle {
  margin: 0;
  letter-spacing: 3px;
  color: #000;
  font-family: "MontserratSemiBold";
  font-size: 20px;

  @media (max-width: 992px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    color: #fff;
  }
}

.linkText {
  font-size: 12px;
  color: #000;

  @media (max-width: 992px) {
    font-size: 10px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    letter-spacing: 1.5px;
    color: #fff;
  }
}

.cassette {
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 800px;
  height: 630px;
  background-image: url(../../../assets/image/vhs.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: drop-shadow(-10px 10px 20px #ca1782);
  left: 10%;

  @media (max-width: 1400px) {
    left: 2%;
  }

  @media (max-width: 1200px) {
    width: 600px;
    height: 500px;
  }

  @media (max-width: 768px) {
    width: 400px;
    height: 300px;
    bottom: 20%;
    left: 20px;
  }

  @media (max-width: 541px) {
    bottom: 20%;
    left: -20%;
  }

  @media (max-width: 430px) {
    width: 350px;
    height: 300px;
    left: -25%;
    bottom: 35%;
  }
}