.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  line-height: 20px;
  background-color: #c91681;
  color: #fff;
  text-align: center;
  padding: 25px;

  &:hover {
    text-decoration: underline;
    background-color: #d61a8a;
  }

  &:disabled {
    background-color: #d8409b;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 20px;
  }
}