.content {
  margin-top: 20px;
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column;
    margin-top: 70px;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.description {
  width: 50%;
  padding: 0 15px;

  p,
  h3 {
    margin-bottom: 20px;
    padding-right: 20px;
  }

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    order: 1;
  }
}

.title {
  font-weight: 300;
  font-size: 60px;
  margin-top: 20px;
  margin-bottom: 50px;

  @media (max-width: 992px) {
    font-size: 45px;
  }
  
  @media (max-width: 768px) {
    display: none;
  }
}

.titleMobile {
  font-weight: 300;
  text-align: center;
  font-size: 30px;
  margin-top: 25px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}