.titleContainer {
  width: 100%;
  height: 65px;
  padding-top: 20px;
  margin-bottom: 50px;
  box-sizing: content-box;

  @media (max-width: 768px) {
    margin-bottom: 30px;
    height: 35px;
  }
}

.title{
  height: 100%;
}

.text {
  height: 25vh;
  margin-bottom: 20px;
}