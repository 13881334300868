.infoContentBlock{
  display: grid;
  gap: 50px;
  max-width: 900px;
}
.infoText {
  display: grid;
  gap: 15px;

  @media (max-width: 768px) {
    order: 1;
    margin-top: 25px;
    margin-bottom: -35px;
  }
}