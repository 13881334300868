.container {
  user-select: none;
}

.main {
  font-weight: 300;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.title {
  font-weight: 300;
  font-size: 48px;
  letter-spacing: -2px;
  margin-bottom: 10px;
  color: #c91681;

  @media (max-width: 1200px) {
    font-size: 38px;
  }

  @media (max-width: 992px) {
    font-size: 30px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.link {
  color: #1f67ff;
  text-decoration: underline;
}

.short {
  font-size: 14px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.audience {
  color: #c91681;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    line-height: 1;
  }

  span {
    font-weight: 500;
    font-size: 30px;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
}

.placeholderWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    width: calc(100% + 15px);
    margin-left: -15px;
  }
}

.counterPlaceholder {
  height: 36px;
  margin: 10px 0 0;
  width: 50px;

  @media (max-width: 768px) {
    height: 28px;
  }
}

.titlePlaceholder {
  height: 45px;
  margin: 20px 0;
  width: 70%;

  @media (max-width: 1200px) {
    height: 35px;
  }

  @media (max-width: 992px) {
    height: 28px;
  }

  @media (max-width: 768px) {
    height: 22px;
    margin: 10px 0;
  }
}

.shortPlaceholder {
  height: 40px;
  margin-top: 15px;

  @media (max-width: 768px) {
    height: 32px;
    margin-top: 10px;
  }
}

.linkPlaceholer {
  height: 14px;
  margin-top: 10px;
  width: 150px;

  @media (max-width: 768px) {
    height: 18px;
  }
}