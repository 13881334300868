.wrapper {
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 992px) {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 0;
    background-color: #fff;
    z-index: 10;

    &::after {
      content: "";
      background-color: #c91681;
      height: 5px;
      width: calc(100%/5);
      position: absolute;
      bottom: -2px;
      transition: all 0.2s ease-in;
    }

    &.scrolled::after {
      width: 100%;
      height: 3px;
    }
  }
}

.navContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;

  @media (max-width: 992px) {
    align-items: center;
    justify-content: flex-end;
    padding: 0;
  }
}

.homeLink {
  height: max-content;
  line-height: 20px;
  padding: 5px 0;
  color: #000;
  font-size: 12px;

  @media (max-width: 992px) {
    margin-right: 0;
  }
}

.homeLinkTitle {
  font-family: 'MontserratSemiBold';
  font-size: 20px;
  letter-spacing: 2.5px;
  color: #000;
  margin: 0;
}

.navigation {
  display: flex;
  height: max-content;
  font-size: 16px;
  color: #000;
  transition: color 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);

  @media (max-width: 992px) {
    display: none;
  }
}

.linkWrapper:hover {
  background-color: #c91681;
  color: #fff;
  border-radius: 5px;
  transition: background 0.5s cubic-bezier(0.4, 0, 1, 1), color 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);

  .activeLink {
    color: #fff;
  }
}

.logo {
  margin-top: -10px;

  @media (max-width: 992px) {
    display: none;
  }
}

.link {
  display: block;
  color: inherit;
  padding: 15px;

  &.activeLink {
    color: #c91681;
  }
}

.phone {
  display: block;
  color: inherit;
  padding: 15px 0;
  height: max-content;
  font-size: 16px;

  @media (max-width: 992px) {
    display: none;
  }
}

.burger {
  display: none;
  position: fixed;
  width: 30px;
  height: 20px;
  padding: 0;
  margin-left: 15px;
  top: 23px;
  left: 20px;
  z-index: 20;
  z-index: 101;
  background-color: transparent;

  @media (max-width: 992px) {
    display: block;
  }

  &.active {
    .burgerSpan {
      transform: rotate(45deg);

      &::before {
        transform: rotate(-90deg) translateX(-8px);
      }

      &::after {
        opacity: 0;
        transform: translateX(-10px);
      }
    }
  }
}

.burgerSpan,
.burgerSpan::before,
.burgerSpan::after {
  display: block;
  background-color: #888;
  height: 2px;
  position: absolute;
  left: 0;
  border-radius: 2px;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  opacity: 1;
}

.burgerSpan {
  width: 30px;

  &::before {
    content: "";
    bottom: 9px;
    width: 30px;
  }

  &::after {
    top: 9px;
    content: "";
    width: 20px;
  }
}