.wrapper {
  position: relative;
  margin-bottom: 10px;

  &[data-status="succes"] {
    .label {
      color: #4b86ff;
    }

    .select {
      border-color: #4b86ff;
      color: #4b86ff;
    }
  }

  &[data-status="error"] {
    .label {
      color: #ff0000;
    }

    .select {
      color: #ff0000;
      border-color: #ff0000;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);

      &:focus {
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
        border-color: #ff0000;
      }
    }
  }
}

.label {
  display: block;
  margin: 0 0 15px;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.select {
  display: block;
  width: 100%;
  height: 70px;
  padding: 15px 18px;
  font-size: 16px;
  line-height: 18px;
  border: 2px solid #c4c4c4;
  color: #555;
  background-color: transparent;
  border-radius: 0;
  font-family: inherit;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

  option {
    color: #555;
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #67b168;
    border-color: #4b86ff;
  }

  @media (max-width:768px) {
    height: 54px;
    padding: 17px 18px;
    font-size: 14px;
    line-height: 20px;
  }
}

.helpBlock {
  margin-top: 3px;
  color: #ff0000;
  margin-bottom: 0;
}