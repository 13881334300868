@font-face {
  font-family: 'RobotoMono';
  src: url(../src/assets/fonts/RobotoMono-VariableFont.ttf);
  font-display: swap;
}

@font-face {
  font-family: "MontserratSemiBold";
  src: url(../src/assets/fonts/MontserratSemiBold.ttf);
  font-display: swap;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: inherit;
  padding: 0;
  font-weight: 400;
  font-family: inherit;
}

b,
strong {
  font-weight: 700;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  border: none;
}

body {
  margin: 0;
  max-width: 100vw;
  color: #333;
  font-size: 14px;
  line-height: 1.42857143;
  font-family: 'RobotoMono';
}

strong {
  font-weight: 500;
}

#root {
  max-height: 100vh;
  overflow: auto;
}

p {
  margin-bottom: 10px;
}

input[type="date"] {
  cursor: pointer;
}

.grecaptcha-badge {
  display: none !important;
}