.wrapper {
  padding: 70px 120px 80px;
  width: 600px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 50px 20px;
  }
}

.title {
  font-weight: 300;
  margin: 0 0 35px;
  font-size: 48px;
  line-height: 60px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 0 20px;
    font-size: 30px;
    line-height: 36px;
  }
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 0 40px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 0 25px;
    font-size: 14px;
    line-height: 20px;
  }
}

.submit {
  width: 100%;
}