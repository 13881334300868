.button {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 1px solid #c91681;

  &::before,
  &::after {
    content: "";
    right: 3.5px;
    width: 40px;
    height: 2px;
    position: absolute;
    top: 23.5px;
    background-color: #c91681;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}