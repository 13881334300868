.sliderContainer {
  @media (max-width: 768px) {
    padding: 0;
  }
}

.specOffersSlider {
  height: 100%;
  padding-bottom: 6px !important;

  @media (max-width: 768px) {
    padding: 0 !important;
  }
}

.title {
  font-weight: 300;
  font-size: 60px;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 992px) {
    font-size: 45px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.slide {
  width: 100%;

  @media (max-width: 992px) {
    width: 750px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.slideLink {
  display: block;
  margin-left: 12px;
  margin-right: 12px;
  height: 500px;

  @media (max-width: 992px) {
    height: 350px;
  }

  @media (max-width: 768px) {
    margin: 0;
    height: auto;
  }
}

.slideImage {
  object-fit: cover;
  object-position: center;
}

.slideTitle {
  display: block;
  font-size: 26px;
  text-align: center;
  margin-top: 25px;
  color: #c91681;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 0 15px;
  }
}

.placeholderImage {
  height: 500px;

  @media (max-width: 992px) {
    height: 350px;
  }

  @media (max-width: 768px) {
    height: 250px;
  }
}

.placeholderTitle {
  height: 47px;
  margin-top: 25px;
  margin-inline: auto;
  width: 85%;
}