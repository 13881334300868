.wrapper {
  height: 500px;
  position: relative;
  margin-top: 50px;
  margin-bottom: -55px;

  @media (max-width: 768px) {
    background-image: none;
    height: max-content;
    margin-bottom: unset;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;

  @media (max-width: 768px) {
    display: none;
  }
}

.buttons {
  position: relative;
  padding-top: 300px;
  z-index: 1;

  @media (max-width: 768px) {
    padding-top: 0;

    >div {
      padding: 0 15px;
    }
  }
}