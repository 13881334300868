.wrapper {
  position: relative;
  width: 580px;
  padding: 80px;
  font-family: Arial, Helvetica, sans-serif;

  @media (max-width: 768px) {
    width: 100%;
    padding: 80px 20px 30px;
  }
}

.title {
  font-weight: 300;
  margin: 0 0 25px;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  font-family: 'RobotoMono';

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
}

.closeBtn {
  position: absolute;
  top: 40px;
  right: 40px;

  @media (max-width: 768px) {
    top: 20px;
    right: 20px;
  }
}

.submit {
  margin-top: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.error {
  color: #ff0000;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
}