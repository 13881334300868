.subscribe {
  @media (max-width: 992px) {
    margin-top: 50px;
    padding: 0 30px;
    position: relative;

    &::after {
      content: "";
      height: 2px;
      width: calc(100% - 30px);
      top: -30px;
      position: absolute;
      left: 15px;
      background-color: #737373;
      box-sizing: border-box;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &[data-status="succes"] {
    .input {
      border-color: #3c763d;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);

      &:focus {
        border-color: #2b542c;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #67b168;
      }
    }
  }

  &[data-status="error"] {
    .input {
      border-color: #a94442;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);

      &:focus {
        border-color: #843534;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
      }
    }
  }

  >h2 {
    font-size: 16px;
    color: #000;
    margin-top: 10px;
    font-weight: 500;
  }

  >p {
    margin: 10px 0;
  }
}

.input {
  font-weight: 300;
  padding: 22px 60px 22px 14px;
  font-size: 12px;
  width: max-content;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

  @media (max-width: 992px) {
    width: 100%;
  }

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
}

.submit {
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  width: max-content;
  color: white;
  background-color: #c91681;
  border: none;
  padding: 14px 50px;
  margin: 10px 0;

  &:hover {
    background-color: #d61a8a;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
}